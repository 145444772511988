import { some, get } from "lodash";

export const rateCancellationDate = (rate) => {
  return get(
    rate,
    "payment_options.payment_types[0].cancellation_penalties.free_cancellation_before",
    null
  );
};

export const rateTotalPrice = (rate) => {
  return parseFloat(
    get(
      rate,
      "payment_options.payment_types[0].commission_info.show.amount_gross",
      "0"
    )
  );
};

export const hasHotelSegmentRateWithCancellation = (segment) => {
  return some(get(segment, "meta.rates", []), (rate) => {
    return rateCancellationDate(rate) !== null;
  });
};

export const findSegmentPickedRate = (segment) => {
  const roomExtraFieldIndex = 0;
  const pickedRoomAmount =
    parseFloat(segment.amount) +
    parseFloat(segment.extra_fields[roomExtraFieldIndex].markup.selected.value);
  const matchingRate = get(segment, "meta.rates", []).find(
    (rate) => rateTotalPrice(rate) === pickedRoomAmount
  );
  return typeof matchingRate !== "undefined" ? matchingRate : null;
};
