var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"creator-v3-room"},[_c('creator-v3-container',{attrs:{"theme":_vm.name,"title":_vm.title},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"creator-v3-room__content"},[(!_vm.isValid)?_c('alert-box-v3',[_vm._v(" Aby przejść dalej, przydziel wszystkie osoby do "),(_vm.allLocationsHaveHotelPreferred)?[_vm._v("pokojów")]:(_vm.allLocationsHaveApartmentPreferred)?[_vm._v("apartamentów")]:[_vm._v("pokojów/apartamentów")]],2):_vm._e(),_c('div',{staticClass:"creator-v3-room__items-with-bullets"},_vm._l((_vm.locationTagInfo),function(info,index){return _c('div',{key:`room-${info.id}`,staticClass:"creator-v3-room__items-with-bullets-item"},[(_vm.locationTagInfo.length > 1)?_c('div',{staticClass:"creator-v3-room__items-with-bullets-line",class:{
                'creator-v3-room__items-with-bullets-line--last':
                  index === _vm.locationTagInfo.length - 1,
              }},[_c('div',{staticClass:"creator-v3-room__items-with-bullets-bullet"})]):_vm._e(),_c('div',[_c('creator-v3-tag',[_vm._v(" "+_vm._s(info.title)+" ")]),_c('button',{staticClass:"button-v3 button-v3--secondary button-v3--full-width creator-v3-room__options-button",on:{"click":function($event){return _vm.onShowRoomDetailsPanel(info.id)}}},[_vm._v(" Wybierz wyżywienie oraz opcje i liczbę "),(info.isHotelPreferred)?[_vm._v("pokojów")]:[_vm._v("apartamentów")]],2),(info.tags.length > 0)?_c('creator-v3-tags',{key:`room-${info.id}-${info.roomIds}-${info.tags.join()}`,model:{value:(info.tags),callback:function ($$v) {_vm.$set(info, "tags", $$v)},expression:"info.tags"}}):(_vm.locationTagInfo.length > 1)?_c('div',{staticClass:"creator-v3-room__tags-white-space-filler"}):_vm._e()],1),(
                index !== _vm.locationTagInfo.length - 1 &&
                _vm.locationTagInfo.length > 0
              )?_c('div',{staticClass:"creator-v3-room__item-separator"}):_vm._e()])}),0)],1)]},proxy:true}])}),(_vm.showRoomDetailsPanel)?_c('creator-v3-options-panel',{on:{"close":_vm.onOptionsPanelClose},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Liczba "),(_vm.isHotelPreferred(_vm.selectedLocation))?[_vm._v(" pokojów ")]:[_vm._v(" apartamentów ")]]},proxy:true},{key:"content",fn:function(){return [_c('creator-v3-location-room-configuration',{attrs:{"location":_vm.selectedLocation,"isValid":_vm.isValid}})]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" Wybierz opcje i liczbę "),(_vm.isHotelPreferred(_vm.selectedLocation))?[_vm._v(" pokojów ")]:[_vm._v(" apartamentów ")]]},proxy:true}],null,false,3952435753)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }