export default {
  props: {
    type: {
      type: String,
      default: "warning",
    },
  },
  computed: {
    classModifiers() {
      return {
        "alert-box-v3": true,
        [`alert-box-v3--${this.type}`]: this.type,
      };
    },
  },
};
