import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";
import formMixin from "@/mixins/form";
import {
  findSegmentPickedRate,
  rateCancellationDate,
} from "@/helpers/segments";

export default {
  mixins: [formMixin],
  computed: {
    ...mapGetters("plan", ["hotelSegmentsWithCancellationPicked"]),
    ...mapState("plan", ["currentPlan"]),
  },
  methods: {
    ...mapActions("plan", ["addSegmentToPayLayer", "removeSegmentToPayLayer"]),
    isToggleChecked(segmentId) {
      return this.currentPlan.payLaterSegments.includes(segmentId);
    },
    onToggleChange(segmentId, checked) {
      if (checked) {
        this.addSegmentToPayLayer(segmentId);
      } else {
        this.removeSegmentToPayLayer(segmentId);
      }
    },
    paymentDateForSegment(segment) {
      const paymentDate = rateCancellationDate(findSegmentPickedRate(segment));
      return moment(paymentDate)
        .locale("pl")
        .subtract(2, "days")
        .format("YYYY-MM-DD HH:mm");
    },
  },
};
