import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";
import formMixin from "@/mixins/form";
import planLayoutTripData from "@/components/plan/plan-layout-trip-data/plan-layout-trip-data.vue";
import planLayoutNextButtons from "@/components/plan/plan-layout-next-buttons/plan-layout-next-buttons.vue";
import stepMixin from "@/mixins/step";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import { MODALS } from "@/store/modules/modal";

const DEBOUNCE_TIME = 750;
const DISABLED_PLAN_IDS = [1588];

export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  mixins: [formMixin, stepMixin],
  components: {
    alertBoxV3,
    planLayoutTripData,
    planLayoutNextButtons,
  },
  data() {
    return {
      code: null,
      codeValid: false,
    };
  },
  computed: {
    ...mapGetters("plan", [
      "priceWithoutInsurance",
      "priceOfInsurance",
      "hasInsurance",
      "provision",
      "totalPrice",
      "shouldShowPayTotalToggle",
    ]),
    codeInputClassModifiers() {
      return {
        "plan-summary-and-payment__code-input--valid": this.codeValid,
      };
    },
    showPromoCodeInfo() {
      return this.plan.preview && this.plan.code;
    },
    promoCodeInfoLabel() {
      return `Użyto kod promocyjny (${this.plan.code.toFixed(1)}% zniżki)`;
    },
    showPlanConfirmButtons() {
      return !this.plan.preview && !DISABLED_PLAN_IDS.includes(this.plan.id);
    },
  },
  methods: {
    ...mapActions("plan", ["checkPromoCode", "setPromoCode"]),
    ...mapActions("modal", ["setCurrentModal"]),
    configurePayLaterHotels() {
      this.setCurrentModal({
        name: MODALS.HOTEL_DELAYED_PAYMENTS_SETUP,
      });
    },
    onPayTotalNowChange(value) {
      if (!value) {
        this.configurePayLaterHotels();
      }
    },
  },
  created() {
    this.promoCodeCheckerFn = debounce(
      (val) => {
        if (!val) return;
        this.checkPromoCode(val)
          .then((data) => {
            this.codeValid = data.is_available;
            if (data.is_available) {
              this.setPromoCode({
                code: data.value,
                codeName: val,
              });
            } else {
              this.setPromoCode({});
            }
            return data;
          })
          .catch(() => {
            this.codeValid = false;
            this.setPromoCode({});
          });
      },
      DEBOUNCE_TIME,
      { trailing: true }
    );
    this.$watch("code", (val) => this.promoCodeCheckerFn(val));
  },
};
