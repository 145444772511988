import { mapActions } from "vuex";
import formMixin from "@/mixins/form";
import { loadScript } from "@/helpers/utils";
import config from "@/config";

export default {
  mixins: [formMixin],
  props: {
    plan: {
      type: Object,
      required: false,
    },
    theme: {
      type: String,
      default: "",
    },
    showCodeReminder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: {
        pin: "",
      },
    };
  },
  computed: {
    classModifiers() {
      return {
        "plan-pin-guard": true,
        [`plan-pin-guard--${this.theme}`]: this.theme,
      };
    },
    codeIsInvalid() {
      return !this.fields.pin || this.fields.pin.length !== 6;
    },
  },
  methods: {
    ...mapActions("plan", ["resendPlanPin", "checkPlanPin"]),
    verify() {
      loadScript(config.captchaUrl, "grecaptcha").then((grecaptcha) => {
        grecaptcha
          .execute(config.captchaSiteKey, { action: "pinVerify" })
          .then((token) => {
            const data = {
              id: this.plan.id,
              payload: {
                pin: this.fields.pin,
                captcha_token: token,
              },
            };
            this.checkPlanPin(data).then(() => {
              this.$emit("verified", { plan: this.plan });
            });
          });
      });
    },
    resend() {
      loadScript(config.captchaUrl, "grecaptcha")
        .then((grecaptcha) => {
          grecaptcha
            .execute(config.captchaSiteKey, { action: "pinResend" })
            .then((token) => {
              const data = {
                id: this.plan.id,
                payload: {
                  captcha_token: token,
                },
              };
              this.resendPlanPin(data).then(() => {
                this.$router.push({ name: "pin-resend-confirmation" });
              });
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    loadScript(config.captchaUrl, "grecaptcha");
  },
};
