import {
  planSectionTitle,
  stripTags,
  loadScript,
  preloadPlanImages,
} from "@/helpers/utils";
import { isEqual } from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import flowMixin from "@/mixins/flow";
import pagePlanMixin from "@/mixins/pagePlan";
import planPinMixin from "@/mixins/planPin";
import planDetailsMobileHeader from "@/components/plan/details/plan-details-mobile-header/plan-details-mobile-header.vue";
import planDetailsMobileNav from "@/components/plan/details/plan-details-mobile-nav/plan-details-mobile-nav.vue";
import planDetailsSideNav from "@/components/plan/details/plan-details-side-nav/plan-details-side-nav.vue";
import planDetailsArrowNav from "@/components/plan/details/plan-details-arrow-nav/plan-details-arrow-nav.vue";
import planSegmentSection from "@/components/plan/plan-segment-section/plan-segment-section.vue";
import planSuggestionForm from "@/components/plan/plan-suggestion-form/plan-suggestion-form.vue";
import planPriceSummary from "@/components/plan/details/plan-price-summary/plan-price-summary.vue";
import planSummaryAndPayment from "@/components/plan/details/plan-summary-and-payment/plan-summary-and-payment.vue";
import scrollTo from "@/helpers/scrollTo";
import { PLAN_TYPE, SEGMENTS, FIELDS } from "@/constants";
import config from "@/config";
import api from "@/services/api";
import { MODALS } from "@/store/modules/modal";

const SHOW_MOBILE_HEADER_WIDTH = 1024;
const LAST_STEP = {
  SECTION: 1000,
  TITLE: "Podsumowanie i Dane",
};

export default {
  mixins: [flowMixin, pagePlanMixin, planPinMixin],
  props: {
    sign: {
      type: String,
      required: false,
    },
    transactionId: {
      type: String,
      required: false,
    },
  },
  components: {
    planSuggestionForm,
    planSegmentSection,
    planDetailsMobileHeader,
    planDetailsMobileNav,
    planDetailsSideNav,
    planDetailsArrowNav,
    planPriceSummary,
    planSummaryAndPayment,
  },
  metaInfo() {
    if (!this.currentPlan.id) {
      return {};
    }
    const description = stripTags(this.currentPlan.description);
    const title = `${this.currentPlan.title}`;
    const image = this.currentPlan.background;
    let link = [];
    if (this.currentPlan.type === PLAN_TYPE.INSPIRATION) {
      // backwards compatibility for when inspiration linked to plan/details
      const resolved = this.$router.resolve({
        name: "page-inspiration-details-slug",
        params: {
          sign: this.currentPlan.slug,
        },
      });
      link = [
        {
          rel: "canonical",
          href: window.location.origin + resolved.route.path,
        },
      ];
    }
    return {
      title,
      link,
      meta: [
        {
          property: "og:title",
          content: title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: image,
          vmid: "og:image",
        },
        {
          name: "description",
          content: description,
          vmid: "description",
        },
        {
          name: "twitter:title",
          content: title,
          vmid: "twitter:title",
        },
        {
          name: "twitter:description",
          content: description,
          vmid: "twitter:description",
        },
        {
          name: "twitter:image",
          content: image,
          vmid: "twitter:image",
        },
      ],
    };
  },
  data() {
    return {
      overview: false,
      showSuggestionForm: false,
      loginActive: false,
    };
  },
  watch: {
    currentPlan(value) {
      if (value) {
        this.checkPlanAccess(value);
        preloadPlanImages({ plan: value });
      }
    },
    hotelSegmentsWithCancellationPicked(newValue, oldValue) {
      if (!isEqual(newValue, oldValue) && !this.currentPlan.preview) {
        this.clearPayLaterSegments();
        this.clearPayTotalNow();
      }
    },
    "currentPlan.payTotalNow"(newValue, oldValue) {
      if (
        !isEqual(newValue, oldValue) &&
        newValue &&
        !this.currentPlan.preview
      ) {
        this.clearPayLaterSegments();
      }
    },
  },
  computed: {
    ...mapState("app", ["width"]),
    ...mapGetters("plan", [
      "groupedPlanSegmentsBySection",
      "currentPlanSegments",
      "hotelSegmentsWithCancellationPicked",
    ]),
    classModifiers() {
      return {
        "page-plan-details": true,
        "page-plan-details--no-access": this.guardCheckRequired,
        "page-plan-details--not-available": this.isPlanNotAvailable,
        "page-plan-details--overview": this.overview,
      };
    },
    isPlanNotAvailable() {
      return this.currentPlan.id && this.currentPlan.is_available === false;
    },
    groupedSegmentsSteps() {
      return this.groupedPlanSegmentsBySection.map((segments) => {
        return {
          section: segments[0].section,
          title: planSectionTitle({ segments, full: true }),
        };
      });
    },
    sectionSegments() {
      return this.currentPlanSegments.filter(
        (i) => i.section === this.currentStep.section
      );
    },
    steps() {
      return [
        ...this.groupedSegmentsSteps,
        {
          title: LAST_STEP.TITLE,
          section: LAST_STEP.SECTION,
        },
      ];
    },
    isFirstStep() {
      return this.currentStepIndex === 0;
    },
    isLastStep() {
      return this.currentStepIndex === this.lastStepIndex;
    },
    priceClassModifiers() {
      return {
        "page-plan-details__price": true,
        "page-plan-details__price--login-active": this.loginActive,
      };
    },
    showMobileHeader() {
      return this.width < SHOW_MOBILE_HEADER_WIDTH;
    },
  },
  methods: {
    ...mapActions("plan", [
      "getTransactionById",
      "clearTransaction",
      "clearPayLaterSegments",
      "clearPayTotalNow",
    ]),
    ...mapActions("modal", ["setCurrentModal", "clearModal"]),
    onActivateSection(data) {
      const { section } = data;
      // sections are numbered from 1 and in our case steps grouped by sections are regular array
      const stepIndex = section - 1;
      this.onStep(stepIndex);
    },
    onLoginActive(value) {
      this.loginActive = value;
    },
    headlineIconModifiers(headline) {
      return {
        "page-plan-details__headline-icon": true,
        [`page-plan-details__headline-icon--${headline.icon}`]: headline.icon,
      };
    },
    askForPlanNewVersion() {
      this.showSuggestionForm = true;
    },
    hidePlanOverview() {
      this.overview = false;
    },
    showPlanOverview() {
      this.overview = true;
      scrollTo(0);
    },
    getInsuranceInfo() {
      return loadScript(config.captchaUrl, "grecaptcha").then((grecaptcha) => {
        return grecaptcha
          .execute(config.captchaSiteKey, { action: "insuranceInfo" })
          .then((token) => {
            const payload = {
              captcha_token: token,
            };
            // check if we need to treat is as allianz segment
            return api.insurance
              .getInsuranceAgreement({ id: this.currentPlan.id, payload })
              .then((response) => response.data);
          });
      });
    },
    sendInsuranceOwu() {
      return loadScript(config.captchaUrl, "grecaptcha").then((grecaptcha) => {
        return grecaptcha
          .execute(config.captchaSiteKey, { action: "insuranceOwu" })
          .then((token) => {
            const payload = {
              captcha_token: token,
            };
            return api.insurance.sendInsuranceOwu({
              id: this.currentPlan.id,
              payload,
            });
          });
      });
    },
    checkIsOnInsuranceAndCanProgress() {
      if (this.sectionSegments.length === 0 || this.currentPlan.preview) {
        return Promise.resolve(true);
      }
      const isCurrentSegmentInsurance =
        this.sectionSegments[0].kind === SEGMENTS.INSURANCE;
      if (!isCurrentSegmentInsurance) {
        return Promise.resolve(true);
      }

      const segment = this.sectionSegments[0];
      const selectField = segment.extra_fields.find(
        (i) => i.kind === FIELDS.SELECT
      );
      if (!selectField) {
        return Promise.resolve(true);
      }

      if (!selectField.markup.selected.value) {
        return Promise.resolve(true);
      }

      return this.getInsuranceInfo()
        .then((info) => {
          if (!info.owu_link) {
            // old insurance segment or manually created
            return Promise.resolve(true);
          }
          return this.sendInsuranceOwu()
            .then(() => Promise.resolve(false))
            .catch(() => Promise.resolve(false));
        })
        .then((value) => {
          return Promise.resolve(value);
        })
        .catch(() => {
          return Promise.resolve(true);
        });
    },
    onSkipInsuranceModal() {
      this.clearModal();
      const segment = this.sectionSegments[0];
      const selectField = segment.extra_fields.find(
        (i) => i.kind === FIELDS.SELECT
      );
      selectField.markup.selected = selectField.markup.options[0];
    },
    onNext() {
      this.checkIsOnInsuranceAndCanProgress().then((progress) => {
        if (progress) {
          return this.onStepNext();
        } else {
          this.setCurrentModal({
            name: MODALS.PLAN_INSURANCE_INFO,
            options: {
              skipFn: this.onSkipInsuranceModal,
              progressFn: () => {
                this.clearModal();
                this.onStepNext();
              },
            },
          });
        }
      });
    },
    onPrevious() {
      return this.onStepPrevious();
    },
    onStep(step) {
      this.checkIsOnInsuranceAndCanProgress().then((progress) => {
        if (progress) {
          this.currentStepIndex = step;
        } else {
          this.setCurrentModal({
            name: MODALS.PLAN_INSURANCE_INFO,
            options: {
              skipFn: this.onSkipInsuranceModal,
              progressFn: () => {
                this.clearModal();
                this.currentStepIndex = step;
              },
            },
          });
        }
      });
    },
  },
  created() {
    loadScript(config.captchaUrl, "grecaptcha");
    if (this.sign) {
      this.getPlanBySign(this.sign)
        .then((data) => {
          if (data.type === PLAN_TYPE.INSPIRATION) {
            // backwards compatibility for when inspiration linked to plan/details
            this.$router.push({
              name: "page-inspiration-details-slug",
              params: {
                sign: data.slug,
              },
            });
          }
          if (data.is_available) {
            this.setupPlanChecker();
          }
        })
        .catch(() => {
          this.showPlanDoesNotExistModalIfApiReturnsError();
        });
    } else if (this.transactionId) {
      this.getTransactionById(this.transactionId);
    }
  },
  beforeDestroy() {
    this.clearTransaction();
  },
};
