import { mapActions, mapGetters } from "vuex";
import { STATIC_PAGES } from "@/store/modules/staticPage";

export default {
  data() {
    return {
      STATIC_PAGES,
    };
  },
  computed: {
    ...mapGetters("app", ["isStripeEnabled"]),
  },
  methods: {
    ...mapActions("staticPage", ["setStaticPage"]),
  },
};
