import moment from "moment";
import { isEqual } from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import formMixin from "@/mixins/form";
import { MODALS, HINT_MODAL, VALIDATION_MODAL } from "@/store/modules/modal";
import {
  DEFAULT_MIN_NIGHTS,
  DEFAULT_MAX_NIGHTS,
  DATE_FORMAT_CREATOR_V3,
} from "@/constants";

const WIDE_RANGE_OF_NIGHTS = 6;

const stepMeta = {
  name: "date",
  title: "Data",
};

const MIN_DAYS_FROM = 10;
const MAX_DAYS_SELECTED = 100;
const MAX_MONTHS_TO = 11;

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, formMixin],
  data() {
    return {
      ...stepMeta,
      MAX_DAYS_SELECTED,
      limitationHint: `
      <strong>Dlaczego nie mogę wybrać daty wyjazdu w ciągu najbliższych ${MIN_DAYS_FROM} dni?</strong>
      </br></br>
      W odróżnieniu od biur podróży, nie sprzedajemy gotowych pakietów, ale każdy wyjazd przygotowujemy specjalnie dla Ciebie. Z tego powodu, potrzebujemy więcej czasu na przygotowanie i organizację Twojego wyjazdu. Wprowadzamy usprawnienia, które pozwolą skrócić ten czas.
      </br></br>
      <strong>Dlaczego nie mogę wybrać zakresu dat dłuższego niż ${MAX_DAYS_SELECTED} dni?</strong>
      </br></br>
      Prosimy Cię o wybór w miarę konkretnego zakresu, maksymalnie paru miesięcy, np. całego lata. Dzięki temu będziemy mogli szybko przygotować dla Ciebie propozycję wyjazdu!
      `,
    };
  },
  computed: {
    ...mapState("modal", ["currentModal"]),
    ...mapGetters("creatorV3", ["nightCountMax"]),
    showDateRangeError() {
      return this.offer.dateFrom !== null && this.dateRangeError;
    },
    dateRangeError() {
      const maximumNightsFromRangeOrInput = this.offer.nightsRange[1];
      if (maximumNightsFromRangeOrInput === 0) {
        return false;
      }
      return (
        !maximumNightsFromRangeOrInput ||
        this.nightCountMax < maximumNightsFromRangeOrInput
      );
    },
    maxDaysError() {
      if (this.offer.dateFrom === null || this.offer.dateTo === null) {
        return false;
      }
      const dateFrom = moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3);
      const dateTo = moment(this.offer.dateTo, DATE_FORMAT_CREATOR_V3);
      return dateTo.diff(dateFrom, "days") > MAX_DAYS_SELECTED;
    },
    isValid() {
      if (this.offer.dateFrom === null || this.offer.dateTo === null) {
        return false;
      }

      if (this.dateRangeError) {
        return false;
      }

      const dateFrom = moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3);
      const dateTo = moment(this.offer.dateTo, DATE_FORMAT_CREATOR_V3);

      if (!dateTo.isSameOrAfter(dateFrom)) {
        return false;
      }

      if (this.maxDaysError) {
        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions("modal", [
      "setCurrentModal",
      "setHintModal",
      "clearModal",
      "setValidationModal",
    ]),
    ...mapActions("creatorV3", ["recalculateLocationRanges"]),
    openCalendarModal() {
      this.setCurrentModal({
        name: MODALS.DATE_CALENDAR,
        options: {
          onDateUpdateFn: (val = {}) => {
            this.offer.dateFrom = this.formatDate(val.start);
            this.offer.dateTo = this.formatDate(val.end);
          },
          dateFrom:
            this.offer.dateFrom !== null
              ? moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3).toDate()
              : null,
          dateTo:
            this.offer.dateTo !== null
              ? moment(this.offer.dateTo, DATE_FORMAT_CREATOR_V3).toDate()
              : null,
          labelFrom: "Wylot od",
          labelTo: "Powrót do",
          footerMessageFrom: "Wybierz najwcześniejszą datę wylotu",
          footerMessageTo: "Wybierz najpóźniejszą datę powrotu",
          minDate: moment().add(MIN_DAYS_FROM, "days").toDate(),
          maxDate: moment().add(MAX_MONTHS_TO, "months").toDate(),
        },
      });
    },
    formatDate(date) {
      return date ? moment(date).format(DATE_FORMAT_CREATOR_V3) : null;
    },
    onStepNext() {
      const nightsRangeNotChanged =
        this.offer.nightsRange[0] === DEFAULT_MIN_NIGHTS &&
        this.offer.nightsRange[1] === DEFAULT_MAX_NIGHTS;
      if (nightsRangeNotChanged) {
        this.setHintModal({
          text: `Nie zmieniono liczby nocy na wyjeździe - czy na pewno chcesz wyjechać właśnie na ${DEFAULT_MIN_NIGHTS}-${DEFAULT_MAX_NIGHTS} nocy? Propozycję przygotujemy specjalnie dla Ciebie, więc możesz wskazać dowolną liczbę noclegów!`,
          type: HINT_MODAL.NIGHTS_RANGE,
        });
        if (this.currentModal.name === MODALS.HINT) {
          return {
            preventNavigation: true,
          };
        }
      }
      if (this.offer.nightsRange[0] === 0 && !this.currentModal.name) {
        this.setValidationModal({
          options: {
            type: VALIDATION_MODAL.ZERO_NIGHTS,
            contentHtml:
              "Jako zakres nocy wskazano również 0 (zero).<br/><br/> Czy na pewno interesuje Cię również wyjazd z wylotem i powrotem w ten sam dzień? <br/><br/>Pamiętaj, że Getaway nie posiada gotowych pakietów, ale przygotowuje wyjazd specjalnie dla Ciebie! W związku z tym możesz podać po prostu taką liczbę nocy, która naprawdę Cię interesuje - i taką propozycję wyjazdu otrzymasz!",
            rightButtonFn: () => {
              this.clearModal();
              this.$emit("next");
            },
            rightButtonText: "Idę dalej",
            leftButtonText: "Zmieniam liczbę nocy",
            leftButtonFn: () => {
              this.clearModal();
            },
          },
        });
        if (this.currentModal.name === MODALS.VALIDATION) {
          return {
            preventNavigation: true,
          };
        }
      }
      if (
        this.offer.nightsRange[1] - this.offer.nightsRange[0] >
          WIDE_RANGE_OF_NIGHTS &&
        this.currentModal?.options?.type !== VALIDATION_MODAL.WIDE_NIGHTS_RANGE
      ) {
        this.setValidationModal({
          options: {
            type: VALIDATION_MODAL.WIDE_NIGHTS_RANGE,
            contentHtml:
              "Wybrano szeroki zakres nocy.<br/><br/>Pamiętaj, że Getaway nie posiada gotowych pakietów, ale przygotowuje wyjazd specjalnie dla Ciebie! W związku z tym możesz podać po prostu taką liczbę nocy, która naprawdę Cię interesuje - i taką propozycję wyjazdu otrzymasz!",
            rightButtonFn: () => {
              this.$emit("next");
              this.clearModal();
            },
            rightButtonText: "Idę dalej",
            leftButtonText: "Zmieniam liczbę nocy",
            leftButtonFn: () => {
              this.clearModal();
            },
          },
        });
        if (this.currentModal.name === MODALS.VALIDATION) {
          return {
            preventNavigation: true,
          };
        }
      }
    },
  },
  watch: {
    "offer.nightsRange"(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.recalculateLocationRanges();
      }
    },
  },
};
