import { mapGetters } from "vuex";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import planPerson from "@/components/plan/plan-person/plan-person.vue";
import planInvoice from "@/components/plan/plan-invoice/plan-invoice.vue";
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  components: {
    alertBoxV3,
    planPerson,
    planInvoice,
  },
  computed: {
    ...mapGetters("plan", ["hasInsurance", "hasInsuranceSegment"]),
    rulesLabel() {
      const terms = this.$router.resolve({ name: "static-terms" }).resolved
        .path;
      const privacyPolicy = this.$router.resolve({
        name: "static-privacy-policy",
      }).resolved.path;
      return `Akceptuję <a href="${terms}" target="_blank">Regulamin</a>\
        i <a href="${privacyPolicy}" target="_blank">Politykę Prywatności</a>`;
    },
    showInvoiceCheckbox() {
      return !this.plan.preview || (this.plan.preview && this.plan.hasInvoice);
    },
  },
};
