import formMixin from "@/mixins/form";
import { countries } from "@/helpers/countries";
import moment from "moment/moment";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import {
  REGEXP_EMAIL,
  REGEXP_MOBILE_NUMBER,
  REGEXP_ZIP_CODE,
} from "@/constants";
import { isPeselValid } from "@/helpers/utils";

export default {
  mixins: [formMixin],
  components: {
    alertBoxV3,
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    insuranceOffered: {
      type: Boolean,
      default: false,
    },
    insurancePicked: {
      type: Boolean,
      default: false,
    },
    visa: {
      type: Boolean,
      default: false,
    },
    passport: {
      type: Boolean,
      default: false,
    },
    eu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const yesterday = moment().subtract(1, "days");
    const target = moment().subtract(18, "years");
    let dateOfBirthDisabledDates = {
      from: moment().toDate(),
    };
    let dateOfBirthOpenDate = null;
    if (this.full) {
      dateOfBirthOpenDate = moment().subtract(30, "years").toDate();
      dateOfBirthDisabledDates = {
        from: target.toDate(),
      };
    }
    return {
      dateOfBirthDisabledDates,
      dateOfBirthOpenDate,
      documentDisabledDates: {
        to: yesterday.toDate(),
      },
      optionsCountries: countries
        .map((i) => {
          return {
            label: i.name,
            value: i.code,
          };
        })
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
  },
  computed: {
    isDateOfBirthDisabled() {
      return !this.preview && this.insuranceOffered;
    },
    emailError() {
      if (this.person.email && !REGEXP_EMAIL.test(this.person.email)) {
        return "Niepoprawny adres e-mail";
      }
      return "";
    },
    peselError() {
      if (this.person.pesel && !isPeselValid(this.person.pesel)) {
        return "Niepoprawny numer pesel";
      }
      return "";
    },
    zipCodeError() {
      if (this.person.zip_code && !REGEXP_ZIP_CODE.test(this.person.zip_code)) {
        return "Niepoprawny kod pocztowy: użyj formatu XX-YYY";
      }
      return "";
    },
    phoneNumberError() {
      if (
        this.person.phoneNumber &&
        !REGEXP_MOBILE_NUMBER.test(this.person.phoneNumber)
      ) {
        return "Niepoprawny numer telefonu: 9 cyfr bez spacji, np. 123456789";
      }
      return "";
    },
    classModifiers() {
      return {
        "plan-person": true,
        "plan-person--preview": this.preview,
      };
    },
    identityCardPlaceholder() {
      return this.passport ? "Numer paszportu" : "Numer dowodu lub paszportu";
    },
  },
};
