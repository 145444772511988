import { isNaN } from "lodash";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import formMixin from "@/mixins/form";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import planSegmentLink from "@/components/plan/plan-segment-link/plan-segment-link.vue";
import planSegmentGalleryV2 from "@/components/plan/plan-segment-gallery-v2/plan-segment-gallery-v2.vue";
import { MODALS } from "@/store/modules/modal";
import questionTagText from "@/components/injected/question-tag-text/question-tag-text.vue";
import { waitForInfoTextMap } from "@/helpers/mutex";
import store from "@/store";
import { SEGMENTS } from "@/constants";
import { hasHotelSegmentRateWithCancellation } from "@/helpers/segments";
import { get } from "lodash";

const QuestionTagTextComponent = Vue.extend(questionTagText);

export default {
  mixins: [formMixin],
  props: {
    segment: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    alertBoxV3,
    planSegmentGalleryV2,
    planSegmentLink,
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile", "areHotelDelayedPaymentsEnabled"]),
    ...mapState("searching", ["infoTextMap"]),
    showGallery() {
      return this.segment.images.length > 0;
    },
    showGalleryLink() {
      return this.segment.images.length > 0 && !this.isLayoutMobile;
    },
    localisationLinkTheme() {
      return this.showGalleryLink ? "right" : "";
    },
    classModifiers() {
      return {
        "plan-segment": true,
        "plan-segment--preview": this.preview,
        "plan-segment--skipped": this.segment.skipped,
      };
    },
    showHotelSegmentCancellationInfo() {
      if (!this.areHotelDelayedPaymentsEnabled) {
        return false;
      }
      if (this.segment.kind !== SEGMENTS.HOTEL) {
        return false;
      }
      if (get(this.segment, "meta.rates", []).length === 0) {
        return false;
      }
      return hasHotelSegmentRateWithCancellation(this.segment);
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal"]),
    ...mapActions("plan", [
      "clearPlanFieldRequiredError",
      "setPlanFieldRequiredError",
    ]),
    changeRequiredField({ value, required, segment, fieldIndex }) {
      if (!required) {
        return;
      }

      if (value) {
        this.clearPlanFieldRequiredError({ segment, fieldIndex });
      } else {
        this.setPlanFieldRequiredError({ segment, fieldIndex });
      }
    },
    fieldLabelModifiers(field) {
      return {
        "plan-segment__label--incorrect": !!field.error,
      };
    },
    onGalleryLinkClick() {
      this.setCurrentModal({
        name: MODALS.PLAN_GALLERY,
        options: {
          kind: this.segment.kind,
          images: this.segment.images,
        },
      });
    },
    installQuestionTagsInDescription() {
      const regexp = /\?\?\d+\?\?/g;
      if (!this.segment.description) return;
      const match = this.segment.description.match(regexp);
      if (match !== null) {
        [...new Set(match)].forEach((item) => {
          this.segment.description = this.segment.description.replaceAll(
            item,
            `<span class="plan-segment__question-tag-container"><span class="plan-segment__question-tag">${item}</span></span>`
          );
        });
      }
      waitForInfoTextMap().then(() => {
        this.$nextTick(() => {
          const questionTagEls = this.$el.querySelectorAll(
            ".plan-segment__question-tag"
          );

          for (const el of questionTagEls) {
            const pk = el.innerHTML.replaceAll("??", "");
            const pkNumber = parseInt(pk, 10);
            if (!isNaN(pkNumber) && this.infoTextMap[pk]) {
              const instance = new QuestionTagTextComponent({
                parent: this,
                propsData: {
                  text: this.infoTextMap[pk],
                },
              });
              instance.$mount(el);
            } else if (!isNaN(pkNumber)) {
              el.innerHTML = "";
            }
          }
          const roomNamesEls = this.$el.querySelectorAll(
            ".getaway-ratehawk__room"
          );

          for (const el of roomNamesEls) {
            const images = el
              .getAttribute("data-images")
              .replace(" ", "")
              .split(",")
              .filter((i) => i !== "");
            if (images.length === 0) continue;

            el.addEventListener("click", () => {
              store.dispatch("modal/setCurrentModal", {
                name: MODALS.ROOM_GALLERY,
                options: {
                  images,
                },
              });
            });
          }
        });
      });
    },
  },
  mounted() {
    this.installQuestionTagsInDescription();
  },
};
